:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  -webkit-user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
}

html {
  --background-color: #f7feff;
  --border-bottom: #7f999f7c;
  --button-grey: #9fb2b5;
  --color-white: #fff;
  --text-hover-color: #b2b2b2;
  --menu-item-hover-color: #f4f4fd;
  --color-voco-faded: #ffad5f;
  --color-voco-lighter-faded: #fabf88;
  --color-action-orange: #ff6b00;
  --color-motivator-orange: #ed742e;
  --color-title-orange: #f5ad65;
  --color-text-primary: #0c343d;
  --color-voco-teal: #007078;
  --color-voco-darker-teal: #00828a;
  --color-voco-light-teal: #66d1e3;
  --color-voco-grey-teal: #bfd9db;
  --color-motivator-green: #00b478;
  --voco-button-blue: #40c2d9;
  --voco-light-blue: #dbf5f9;
  --color-modal-background: #01484dcf;
  --color-meeting-background: #3d3d3d;
  --content-width: 68rem;
  --line-spacing: 1.35rem   --font-size-nav: .75rem;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--color-text-primary);
  margin: 0;
  font-family: mokoko, -apple-system, Helvetica Neue, sans-serif;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.link {
  color: var(--voco-button-blue);
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

.p {
  margin-bottom: 1rem;
}

.table tr {
  padding: 5px 0;
}

.table td, .table th {
  vertical-align: middle;
  padding: 0 5px;
}

.table thead {
  font-weight: 700;
}

.h1 {
  text-transform: uppercase;
  font-family: league-gothic, sans-serif;
  font-size: 2.5rem;
  line-height: 2.75rem;
}

.h2 {
  text-transform: uppercase;
  font-family: league-gothic, sans-serif;
  font-size: 1.875rem;
  line-height: 2rem;
}

.h3 {
  font-family: league-gothic, sans-serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.header {
  width: 100%;
  flex-flow: column wrap;
  align-content: stretch;
  display: flex;
}

.content-wrapper {
  max-width: var(--content-width);
  margin: 0 auto;
}

.banner {
  text-align: center;
  height: 2rem;
  padding: .5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
}

@media only screen and (max-width: 768px) {
  .banner {
    font-size: .75rem;
  }
}

.navbar {
  background-color: var(--color-white);
  border-bottom: .2px var(--border-bottom) solid;
  justify-content: center;
  display: flex;
}

.navbar__content {
  flex-grow: 1;
  justify-content: space-between;
  padding: .5rem;
  display: flex;
}

.navbar__items {
  align-items: center;
  display: flex;
}

.navbar__branding {
  margin-top: .5rem;
}

.navbar__branding-link {
  line-height: 100%;
  display: inline-block;
}

.navbar__logo {
  width: auto;
  height: 2.5rem;
  display: block;
}

@media only screen and (max-width: 768px) {
  .navbar__logo {
    height: 2rem;
  }

  .navbar__content {
    padding: .25rem .5rem;
  }
}

.nav-button {
  margin: 0 .5em;
  padding: .75rem;
  font-size: .75rem;
  font-weight: 700;
}

.nav-link-button-active .nav-button {
  background-color: var(--color-voco-faded);
}

.nav-button:hover {
  color: var(--text-hover-color);
}

.nav-link-button-active .nav-button:hover {
  color: var(--color-text-primary);
}

.nav-button__icon {
  height: 27px;
  width: auto;
  display: none;
}

@media only screen and (max-width: 768px) {
  .nav-button {
    margin: 0 .75rem;
    padding: 0;
  }

  .nav-link-button-active .nav-button {
    background-color: #0000;
  }

  .nav-link-button-active .nav-button__icon {
    fill: var(--color-voco-faded);
  }

  .nav-button__icon {
    display: inline-block;
  }

  .nav-button__label {
    display: none;
  }
}

.button {
  cursor: pointer;
  border-radius: 26px;
  line-height: 2rem;
  display: inline-block;
}

.button:disabled {
  background-color: var(--text-hover-color);
  cursor: default;
}

.button--primary {
  color: var(--color-white);
  background-color: var(--voco-button-blue);
  padding: .5rem 2rem;
}

.button--secondary {
  background-color: var(--text-hover-color);
  padding: .5rem 2rem;
}

.dashboard {
  padding: 1rem 0 3rem;
}



.drop-down-menu {
  cursor: pointer;
  padding: .5rem;
  display: inline-block;
  position: relative;
}

.drop-down-menu__content {
  background-color: var(--color-white);
  min-width: 160px;
  z-index: 1;
  display: none;
  position: absolute;
  right: 0;
  box-shadow: 0 3px 6px #00000029;
}

.drop-down-menu__content.drop-down-menu__content--open {
  display: block;
}

.drop-down-menu-link {
  padding: .75rem;
  display: block;
}

.drop-down-menu-link:hover {
  background-color: var(--menu-item-hover-color);
}

.info-card {
  border-style: solid;
  border-width: 4px;
  display: flex;
  position: relative;
}

.info-card__date {
  width: 100px;
  height: 100px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.info-card__date-day {
  font-size: 2rem;
  font-weight: 800;
}

.info-card__date-month, .info-card__no-date-text {
  font-size: .875rem;
  font-weight: 700;
}

.info-card__no-date-icon {
  margin-bottom: .5rem;
}

.info-card__icon {
  width: 100px;
  height: 100px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: .5rem;
  display: flex;
}

.info-card__text-icon {
  color: var(--color-white);
  font-size: 2rem;
  font-weight: 800;
}

.info-card__text {
  height: 100px;
  flex: 1;
  justify-content: left;
  align-items: center;
  padding: 1rem;
  display: flex;
  overflow: hidden;
}

.info-card__link-text {
  color: var(--color-white);
  text-align: center;
  margin-top: .5rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1rem;
  text-decoration: underline;
}

.info-card.info-card--orange {
  border-color: var(--color-action-orange);
}

.info-card.info-card--orange .info-card__icon {
  background-color: var(--color-action-orange);
}

.info-card.info-card--orange .info-card__date {
  color: var(--color-action-orange);
}

.info-card.info-card--teal {
  border-color: var(--color-voco-teal);
}

.info-card.info-card--teal .info-card__icon {
  background-color: var(--color-voco-teal);
}

.info-card.info-card--teal .info-card__date {
  color: var(--color-voco-teal);
}

.info-card .drop-down-menu {
  position: absolute;
  top: -.5rem;
  right: .25rem;
}

.info-card .drop-down-menu__icon {
  width: 28px;
  height: 20px;
  background-color: #f7feffc0;
  padding: .5rem .25rem;
}

@media only screen and (max-width: 1024px) {
  .info-card__text {
    padding: .75rem;
  }
}

@media only screen and (max-width: 390px) {
  .info-card__text {
    font-size: .75rem;
  }
}

.actions__cards {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 1.25rem;
  display: grid;
}

.actions-empty-state {
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  display: flex;
}

.actions-empty-state__message {
  font-family: league-gothic, sans-serif;
  font-size: 1.5rem;
}

@media only screen and (max-width: 825px) {
  .actions__cards {
    grid-template-columns: 1fr;
    row-gap: .5rem;
  }
}

.content-section {
  padding: 1rem;
}

.content-section.content-section--subsection {
  padding: 0;
}

.content-section__heading {
  border-bottom: 4px solid var(--color-text-primary);
  align-items: baseline;
  margin-bottom: 2rem;
  padding-bottom: .25rem;
  display: flex;
}

.content-section--subsection .content-section__heading {
  margin-bottom: 1rem;
}

.content-section__heading.content-section__heading--with-sections {
  border-bottom: none;
  margin-bottom: 1rem;
}

.content-section__title {
  text-transform: uppercase;
  padding-right: 1rem;
  font-family: league-gothic, sans-serif;
  font-size: 2.5rem;
  line-height: 3rem;
}

.content-section__heading--with-sections .content-section__title {
  border-bottom: 4px solid var(--color-text-primary);
  margin-bottom: 2rem;
  padding-right: 4rem;
}

.content-section__heading--subsection .content-section__title {
  font-size: 1.875rem;
  line-height: 2rem;
}

.content-section__info {
  align-self: flex-end;
}

.content-section__action {
  margin-left: auto;
}

.content-section__action-button {
  align-self: flex-start;
  padding-top: 0;
  padding-bottom: 0;
}

.content-section__action--disabled {
  cursor: default;
  color: var(--text-hover-color);
}

.content-section__content {
  margin-bottom: 1rem;
}

.content-section__title--mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .content-section__heading {
    padding-bottom: .5rem;
  }

  .content-section__title--desktop {
    display: none;
  }

  .content-section__title--mobile {
    display: inherit;
  }

  .content-section__heading.content-section__heading--with-sections {
    width: 75%;
  }
}

.loader {
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  display: flex;
}

.loader__message {
  font-family: league-gothic, sans-serif;
  font-size: 1.5rem;
  display: flex;
}

.loader__message-text {
  margin-left: 1rem;
}

.avatar__avatar-image {
  width: 100%;
  height: auto;
  border: 0 solid #0000;
  border-radius: 50%;
}

.avatar__avatar-stacked {
  position: relative;
}

.avatar__avatar-stacked--secondary {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.journal-entry-card {
  margin-bottom: 1.5rem;
  display: flex;
}

.journal-entry-card__avatar {
  width: 4rem;
  flex-shrink: 0;
  margin-right: 1rem;
}

.journal-entry-card__content {
  border: 4px solid var(--color-voco-teal);
  flex-grow: 1;
  margin-left: 40px;
  padding: 1rem 1rem 1rem 0;
  display: flex;
  position: relative;
}

.journal-entry-card__content-callout {
  position: absolute;
  top: 10px;
  left: -41.3px;
}

.journal-entry-card__content-text {
  white-space: pre-line;
}

.journal-entry-card__content-date {
  border-right: 4px solid var(--color-voco-teal);
  color: var(--color-voco-teal);
  width: 100px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: start;
  align-items: center;
  margin-right: 1rem;
  display: flex;
}

.journal-entry-card__content-date-day {
  margin-bottom: .4rem;
  font-size: 2rem;
  font-weight: 800;
}

.journal-entry-card__content-date-month, .journal-entry-card__content-date-year {
  font-size: .875rem;
  font-weight: 700;
}

.journal-entry-card__content-title {
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .journal-entry-card__avatar {
    width: 2.25rem;
    margin-right: 0;
  }

  .journal-entry-card__avatar-image {
    height: 2.25rem;
  }

  .journal-entry-card__avatar-stacked--secondary {
    height: 1.5rem;
    top: 1.25rem;
    left: 1.25rem;
  }

  .journal-entry-card__content-callout {
    width: 30px;
    height: auto;
    top: 5px;
    left: -30px;
  }

  .journal-entry-card__content {
    border-width: 3px;
    padding: .5rem .5rem .5rem 0;
    font-size: .725rem;
  }

  .journal-entry-card__content-date {
    width: 70px;
    border-width: 3px;
    margin-right: .5rem;
  }

  .journal-entry-card__content-date-day {
    margin-bottom: .2rem;
    font-size: 1.25rem;
    line-height: 1rem;
  }

  .journal-entry-card__content-date-month, .journal-entry-card__content-date-year {
    font-size: .625rem;
    line-height: 1.25rem;
  }
}



.member-picker__members {
  flex-wrap: wrap;
  place-content: center;
  margin-bottom: 1rem;
  display: flex;
}

.member-picker__member {
  cursor: pointer;
  width: 8rem;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 0 2.25rem 1rem;
  display: flex;
}

.member-picker__member.member-picker__member--no-click {
  cursor: default;
}

.member-picker__member.member-picker__member--disabled {
  cursor: default;
  pointer-events: none;
}

.member-picker__member--disabled .member-picker__member-add g, .member-picker__member--disabled .member-picker__member-add line {
  stroke: #9ab7bc;
}

.member-picker__member-avatar, .member-picker__member-add {
  width: 4rem;
  height: 4rem;
  margin-bottom: .5rem;
}

.member-picker__member-looking {
  height: 2rem;
  margin: 1rem 1rem 1.5rem;
}

.member-picker__member-avatar .avatar__avatar-image:hover {
  outline: 1px solid var(--color-action-orange);
}

.member-picker__member-avatar--selected .avatar__avatar-image, .member-picker__member-avatar--selected .avatar__avatar-image:hover {
  outline: 4px solid var(--color-action-orange);
}

.member-picker__member-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 8rem;
  overflow: hidden;
}

.member-picker__selected-member {
  background-color: var(--color-voco-teal);
  color: var(--color-white);
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 2rem;
  padding: .25rem;
  display: grid;
}

.member-picker__selected-member-link {
  justify-self: end;
  margin: 0 1rem;
  font-weight: 700;
  text-decoration: underline;
}

.member-picker__selected-member-previous {
  justify-self: center;
  margin: 0 1rem;
}

.member-picker__selected-member-next {
  justify-self: start;
  margin: 0 1rem;
}

.member-picker__selected-member-date {
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .member-picker__members {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
  }

  .member-picker__member {
    padding: 0 .25rem 2rem;
  }

  .member-picker__selected-member {
    display: flex;
  }

  .member-picker__selected-member-previous {
    display: none;
  }

  .member-picker__selected-member-link {
    margin: 0 0 0 auto;
  }

  .member-picker__selected-member-next {
    margin: 0;
  }

  .member-picker__member-avatar .avatar__avatar-image:hover {
    border: 1px solid var(--color-action-orange);
    outline: none;
  }

  .member-picker__member-avatar--selected .avatar__avatar-image, .member-picker__member-avatar--selected .avatar__avatar-image:hover {
    border: 4px solid var(--color-action-orange);
    outline: none;
  }
}

.member-profile-info__header {
  grid-template: "a b" 1fr
                 "a c" 1fr
                 "a d" 1fr
                 / 4rem auto;
  column-gap: .5rem;
  display: grid;
}

.member-profile-info__avatar {
  width: 4rem;
  height: 4rem;
  grid-area: a;
  align-self: center;
}

.member-profile-info__name {
  grid-area: c;
  align-self: center;
  font-weight: 700;
  line-height: 1rem;
}

.member-profile-info__role {
  grid-area: d;
  align-self: start;
  line-height: 1rem;
}

.member-profile-info__text {
  margin: 1rem 0;
}

.modal {
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: var(--color-modal-background);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.modal__wrapper {
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

.modal__content {
  background-color: var(--color-white);
  flex-basis: 920px;
  margin-left: 4.5rem;
  padding: 1rem 1rem 2rem;
}

.modal__content-heart {
  margin: 1rem 0;
}

.modal__content-header {
  border-bottom: 4px solid var(--color-text-primary);
  margin-bottom: 1rem;
  display: flex;
}

.modal__content_info {
  float: left;
  align-self: flex-end;
  margin-bottom: .2rem;
  margin-left: .5rem;
}

.modal__content_title {
  text-transform: uppercase;
  flex-shrink: 1;
  font-family: league-gothic, sans-serif;
  font-size: 2.5rem;
  line-height: 3rem;
}

.modal__content_action {
  flex-shrink: 1;
  margin-bottom: 1rem;
  margin-left: auto;
}

.modal__content-action-in-progress {
  flex-shrink: 1;
  margin-left: auto;
  padding: .5rem 1rem;
}

.modal__dismiss {
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  margin: 0 1rem;
}

.modal__dismiss-button {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .modal {
    max-width: 100vw;
    align-items: flex-start;
    padding: 2rem 0;
    overflow: scroll;
  }

  .modal__wrapper {
    max-width: 100vh;
    flex-direction: column-reverse;
  }

  .modal__content-header {
    flex-direction: column;
  }

  .modal__content_info {
    align-self: flex-start;
    margin-left: 0;
  }

  .modal__content {
    flex-basis: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .modal__dismiss {
    margin-bottom: .5rem;
    margin-left: auto;
  }
}

.action-reschedule-modal__heart {
  margin: 1rem 0;
}

.unpause-pofile-modal__heart {
  margin: 1rem 0;
}

.message-input {
  align-items: flex-start;
  margin-bottom: 1.5rem;
  display: flex;
}

.message-input__avatar {
  width: 4rem;
  flex-shrink: 0;
  margin-right: 1rem;
}

.message-input__input-callout {
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
}

.message-input__input {
  flex-grow: 1;
  position: relative;
}

.message-input__button {
  cursor: pointer;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

.message-input__in-progress {
  position: absolute;
  bottom: 1.25rem;
  right: 3rem;
}

.message-input__text {
  width: 100%;
  height: 7rem;
  background-color: var(--color-white);
  padding: 1rem;
  box-shadow: 0 3px 6px #00000029;
}

.message-input__text::placeholder {
  color: var(--text-hover-color);
}

.message-input__button-image, .message-input__button-image:hover {
  fill: var(--voco-button-blue);
}

.message-input__button:disabled .message-input__button-image {
  fill: var(--text-hover-color);
  cursor: default;
}

@media only screen and (max-width: 768px) {
  .message-input__avatar {
    width: 2.25rem;
    margin-right: 0;
  }
}

.archive-match-modal__member {
  flex-direction: column;
  align-items: center;
  margin: 1rem 2rem;
  display: flex;
}

.archive-match-modal__avatar {
  width: 4rem;
  height: 4rem;
}

.archive-match-modal__firstname {
  margin-top: .5rem;
}

.archive-match-modal__lastname {
  margin-bottom: .5rem;
}

.archive-match-modal__button {
  padding: .25rem 1rem;
}

.archive-match-modal .content-section {
  padding: 1rem 0 0;
}

.archive-match-modal__matches {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .archive-match-modal__matches {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    display: grid;
  }

  .archive-match-modal__member {
    margin: 0;
  }

  .archive-match-modal .content-section__heading {
    flex-direction: column;
  }

  .archive-match-modal .content-section__info {
    align-self: flex-start;
  }
}

.profile {
  padding: 1rem 0 3rem;
}

.profile__intro {
  white-space: pre-line;
}

.text-section {
  background-color: var(--color-white);
  padding: 1rem;
  line-height: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .text-section {
    font-size: .75rem;
    line-height: 1.25rem;
  }
}

.goal-container {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 1.25rem;
  margin-bottom: 2rem;
  display: grid;
}

@media only screen and (max-width: 825px) {
  .goal-container {
    grid-template-columns: 1fr;
    row-gap: .5rem;
  }
}

.career-interests {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem 1.25rem;
  padding: 1rem 4rem 3rem;
  display: grid;
}

.career-interests__interest {
  text-transform: capitalize;
  width: 240px;
  height: 55px;
  text-align: center;
  color: var(--color-voco-teal);
  background-image: url("interest-bubble.78035c0a.svg");
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  justify-self: center;
  align-items: center;
  padding: 1rem 1rem 1rem 2rem;
  font-weight: 800;
  display: flex;
}

@media only screen and (max-width: 920px) {
  .career-interests {
    grid-template-columns: 1fr 1fr;
    row-gap: .5rem;
  }
}

@media only screen and (max-width: 680px) {
  .career-interests {
    grid-template-columns: 1fr;
    row-gap: .5rem;
    margin-bottom: 2rem;
    padding: 0;
  }
}

.career-motivators {
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: grid;
}

.career-motivators__group {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

.career-motivators__group-brace {
  grid-column: 1 / -1;
}

.career-motivators__group-explainer {
  text-align: center;
  grid-column: 1 / -1;
  line-height: 2rem;
}

.career-motivator {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.career-motivator__score {
  color: var(--color-voco-teal);
  margin-bottom: .5rem;
  font-size: 1.5rem;
  font-weight: 800;
}

.career-motivator__title {
  text-align: center;
}

.career-motivators__group--green .career-motivators__group-brace {
  height: 1rem;
  border: 4px solid var(--color-motivator-green);
  border-top: 0;
  grid-column: 1 / -1;
}

.career-motivators__group--green .career-motivator__score, .career-motivators__group--green .career-motivators__group-explainer {
  color: var(--color-motivator-green);
}

.career-motivators__group--orange .career-motivators__group-brace {
  height: 1rem;
  border: 4px solid var(--color-motivator-orange);
  border-top: 0;
  grid-column: 1 / -1;
}

.career-motivators__group--orange .career-motivator__score, .career-motivators__group--orange .career-motivators__group-explainer {
  color: var(--color-motivator-orange);
}

@media only screen and (max-width: 950px) {
  .career-motivators {
    grid-template-columns: 1fr;
  }

  .career-motivators__group {
    margin-bottom: 1rem;
  }
}

.member-info-grid {
  max-width: 768px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem 3rem;
  display: grid;
}

.member-info__label {
  font-size: .875rem;
}

.member-info__value {
  text-overflow: ellipsis;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .member-info-grid {
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
  }
}

@media only screen and (max-width: 500px) {
  .member-info-grid {
    grid-template-columns: 1fr;
  }
}

.edit-intro-text__text {
  width: 100%;
  border: 1px solid var(--color-text-primary);
  height: 12.5rem;
  padding: 1rem;
}

.edit-intro-text__count {
  float: right;
  color: var(--color-voco-teal);
}

.edit-goal-modal__goal {
  display: flex;
}

.edit-goal-modal__goal-number {
  color: var(--color-voco-teal);
  margin-top: 1rem;
  margin-right: 2rem;
  font-size: 2rem;
  font-weight: 800;
}

.edit-goal-modal__goal-title {
  width: 100%;
  border: 1px solid var(--color-text-primary);
  height: 4.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  display: block;
}

.info-motivators__text {
  margin-bottom: 1rem;
}

.info-pen-portrait__text {
  margin-bottom: 1rem;
}

.label-input__label {
  margin-bottom: .5rem;
  display: block;
}

.label-input__input {
  border: 1px solid var(--text-hover-color);
  width: 100%;
  padding: .25rem .5rem;
  font-weight: 700;
  display: block;
}

.label-input__input[type="date"]::-webkit-calendar-picker-indicator {
  width: 1rem;
  -webkit-appearance: none;
  background-image: url("icon-dropdown.0c24b821.svg");
  background-repeat: no-repeat;
  margin: .5rem 0 0;
  padding: 0;
}

.label-input--inline {
  display: flex;
}

.label-input--inline .label-input__label {
  margin-right: 10px;
}

.label-select__label {
  margin-bottom: .5rem;
  display: block;
}

.label-select__select {
  border: 1px solid var(--text-hover-color);
  width: 100%;
  padding: .25rem .5rem;
  font-weight: 700;
  display: block;
}

.current-employement-form {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem 4rem;
  margin-bottom: 1.5rem;
  display: grid;
}

.current-employement-form__input--extended {
  grid-column: 1 / 4;
}

@media only screen and (max-width: 768px) {
  .current-employement-form__input--extended {
    grid-column: 1 / 1;
  }

  .current-employement-form {
    grid-template-columns: 1fr;
    row-gap: 1rem;
    display: grid;
  }
}

.account-page__header {
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

.account-page__avatar {
  width: 4rem;
  height: 4rem;
}

.account-page__avatar-info {
  margin-left: 1rem;
}

.account-page__auth-settings {
  margin-top: 2rem;
}

.account-page__subscription {
  margin-top: 2rem;
  font-weight: 700;
}

.account-page__subscription-status {
  background-color: var(--voco-light-blue);
  border: var(--color-voco-grey-teal) 1px solid;
  border-radius: .25rem;
  padding: .125rem;
}

.account-page__terminate, .account-page__buttons {
  margin-top: 2rem;
}

.edit-account__avatar {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
}

.edit-account__form {
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem 4rem;
  margin-bottom: 1.5rem;
  display: grid;
}

@media only screen and (max-width: 990px) {
  .edit-account__form {
    grid-template-columns: 1fr;
    row-gap: .5rem;
    margin-bottom: 1.5rem;
  }
}

.link-button {
  cursor: pointer;
}

.link-button--primary {
  color: var(--voco-button-blue);
}

.meeting-wrapper {
  width: 100%;
  height: auto;
  background-color: var(--color-meeting-background);
  position: relative;
  overflow: hidden;
}

.meeting {
  overflow: hidden;
}

.meeting-frame {
  width: 100%;
  height: calc(100vh - 220px);
  border: 0;
  position: relative;
}

.meeting-frame.meeting-frame-expands {
  height: calc(100vh - 228px);
  animation: 1s 2s both expand-video;
}

@keyframes expand-video {
  from {
    height: calc(100vh - 228px);
  }

  to {
    height: calc(100vh - 40px);
  }
}

.meeting-nav {
  top: .5rem;
  left: calc(((100vw - var(--content-width)) / 2)  - 1.5rem);
  z-index: 1;
  padding: 0 1.5rem;
  position: absolute;
}

.meeting-nav__logo {
  height: 5rem;
  width: auto;
}

.meeting-frame--welcome {
  justify-content: center;
  align-items: center;
  display: flex;
}

.meeting__welcome {
  background-color: var(--color-white);
  padding: 2rem 3rem;
  position: relative;
}

.meeting__welcome-content {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.meeting__welcome-content > * {
  margin: auto;
}

.meeting__welcome-avatars {
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.meeting__welcome-avatar {
  width: 4rem;
  height: 4rem;
}

.meeting__welcome-info {
  text-align: center;
  margin-bottom: 1.5rem;
}

.meeting__welcome-date {
  text-align: center;
  padding-bottom: 1.5rem;
}

.meeting__welcome-link {
  margin-top: 1rem;
}

.meeting__welcome-speech {
  width: 0;
  height: 0;
  border-left: 50px solid #0000;
  border-top: 40px solid var(--color-white);
  position: absolute;
  bottom: -40px;
  right: 20px;
}

.meeting_content__buttons {
  display: flex;
}

.meeting_content__button {
  background-color: var(--button-grey);
  border-radius: 22px;
  flex-shrink: 0;
  margin-top: 1.25rem;
  margin-right: 1.5rem;
  font-weight: 700;
}

.meeting_content__link {
  margin-top: 1.25rem;
  margin-right: 1.5rem;
  font-weight: 700;
}

.meeting_content__button--active {
  background-color: var(--voco-button-blue);
}

.meeting_content__notes {
  margin-top: 1.5rem;
}

@media only screen and (max-width: 1134px) {
  .meeting-nav {
    left: 0;
  }

  .meeting-nav__logo {
    height: 3rem;
    width: auto;
  }

  .meeting_content__buttons {
    flex-wrap: wrap;
    padding-left: 1.25rem;
  }
}

.title-card {
  padding: 1rem 1.5rem;
}

.title-card--teal {
  background-color: var(--color-voco-light-teal);
  color: var(--color-voco-darker-teal);
}

.title-card--orange {
  background-color: var(--color-voco-lighter-faded);
  color: var(--color-action-orange);
}

.title-card--dark-orange {
  background-color: var(--color-action-orange);
  color: var(--color-white);
}

.title-card-content {
  display: flex;
  overflow: hidden;
}

.title-card__text {
  flex-grow: 1;
}

.title-card__buttons {
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.title-card__subtitle {
  font-size: 1.25rem;
}

.title-card__title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title-card__button {
  border-radius: 22px;
  font-weight: 700;
}

@media only screen and (max-width: 1080px) {
  .title-card__subtitle {
    font-size: .625rem;
    line-height: .75rem;
  }

  .title-card__title {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.theme-content_item {
  margin-top: 1.25rem;
}

.text-card {
  background-color: var(--color-white);
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

.text-card__title {
  text-transform: uppercase;
  font-family: league-gothic, sans-serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.text-card__body > :first-child {
  margin-top: 0;
}

.text-card h1 {
  text-transform: uppercase;
  margin: 2.5rem auto 1.5rem;
  font-family: league-gothic, sans-serif;
  font-size: 2.5rem;
  line-height: 2.75rem;
}

.text-card h2 {
  text-transform: uppercase;
  margin: 2rem auto 1.5rem;
  font-family: league-gothic, sans-serif;
  font-size: 1.875rem;
  line-height: 2rem;
}

.text-card h3 {
  margin: 1.75rem auto 1.5rem;
  font-family: league-gothic, sans-serif;
  font-size: 1.75rem;
  line-height: 1.875rem;
}

.text-card h4 {
  margin: 1.75rem auto 1.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.text-card h5 {
  margin: 1.5rem auto;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.text-card h6 {
  margin: 1.5rem auto;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.text-card p {
  margin-bottom: 1.5rem;
}

.text-card strong {
  font-weight: 700;
}

.text-card em {
  font-style: italic;
  font-weight: 500;
}

.text-card blockquote {
  border-left: 3px solid var(--color-text-primary);
  margin: 1.5rem auto;
  padding: 0 1rem;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.5rem;
  display: block;
}

.text-card figcaption {
  font-size: .875rem;
  font-style: italic;
}

.text-card sub {
  vertical-align: sub;
  font-size: smaller;
}

.text-card sup {
  vertical-align: super;
  font-size: smaller;
}

.text-card ul {
  margin-block: 1em;
  margin-inline: 0;
  margin-bottom: 1.5rem;
  padding-inline-start: 40px;
  list-style-type: disc;
  display: block;
}

.text-card ol {
  margin-block: 1em;
  margin-inline: 0;
  margin-bottom: 1.5rem;
  padding-inline-start: 40px;
  list-style-type: decimal;
  display: block;
}

.text-card a {
  color: var(--voco-button-blue);
  font-weight: 700;
}

.text-card image {
  max-width: 100%;
  height: auto;
}

.text-card figure {
  margin-bottom: 1.5rem;
}

.text-card figure[data-rt-type="video"] {
  position: relative;
}

.text-card figure[data-rt-type="video"] > div:first-child {
  position: absolute;
  inset: 0;
}

.text-card figure[data-rt-type="video"] iframe {
  width: 100%;
  height: 100%;
}

.text-card figure[data-rt-align="center"] {
  text-align: center;
}

.text-card figure[data-rt-align="fullwidth"] {
  margin: 0 auto 1.5rem;
  display: block;
}

.text-card figure[data-rt-align="fullwidth"] img {
  width: 100%;
  height: auto;
  display: block;
}

.text-card figure[data-rt-align="floatleft"] {
  float: left;
  width: 50%;
  margin-right: 1.5rem;
}

.text-card figure[data-rt-align="floatright"] {
  float: right;
  width: 50%;
  margin-left: 1.5rem;
}

.meeting__callout {
  background-color: var(--color-white);
  margin-top: 40px;
  padding: 2rem 3rem;
  position: relative;
}

.meeting__callout-content {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.meeting__callout-content > * {
  margin: auto;
}

.meeting__callout-info {
  text-align: center;
  max-width: 30rem;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
}

.meeting__callout-heading {
  color: var(--color-title-orange);
}

.meeting__callout--off-piste .meeting__callout-heading {
  color: var(--color-action-orange);
}

.meeting__callout-link {
  font-weight: 700;
}

.meeting__callout-speech {
  width: 0;
  height: 0;
  border-right: 50px solid #0000;
  border-bottom: 40px solid var(--color-white);
  position: absolute;
  top: -40px;
  left: 2.5rem;
}

.meeting__callout-close {
  position: absolute;
  top: .75rem;
  right: .75rem;
}

.admin-page__nav-link {
  margin-right: 1rem;
}

.match-queue-page {
  max-width: 90rem;
  margin: 4rem auto;
}

.match-queue-page__controls {
  gap: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.match-queue-page__controls > * {
  flex-grow: 1;
}

.match-queue-page__clear-search {
  flex-grow: 0;
  align-self: flex-end;
  margin-bottom: .25rem;
}

.match-queue-page__button {
  margin-top: 3rem;
  margin-right: 1rem;
}

.member-queue-page__existing-matches-section {
  margin-top: 2rem;
}

.member-queue-page {
  margin-top: 3rem;
}

.member-queue-page__matches-section {
  max-width: 110rem;
  margin: 2rem auto;
}

.member-queue-page__matches {
  margin-top: 2rem;
}

.member-queue-page__matches-linkedin {
  text-align: center;
  padding-top: .25rem !important;
}

.member-queue-page__tabs {
  display: flex;
}

.member-queue-page__tabs > * {
  margin-right: 5px;
}

.member-queue-page__tab-button {
  margin-left: auto;
  padding-top: 0;
  padding-bottom: 0;
}

.member-queue-page__controls {
  gap: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.member-queue-page__controls > * {
  flex-grow: 1;
  margin-top: 1rem;
}

.member-queue-page__clear-search {
  flex-grow: 0;
  align-self: flex-end;
  margin-bottom: .25rem;
}

.match-scores-table__button {
  cursor: pointer;
}

.match-scores-table__button:disabled {
  color: var(--text-hover-color);
  cursor: default;
}

.member-queue-page__matches .label-input__label {
  margin-top: .5rem;
}

.member-queue-page__matches .label-input__input {
  border-color: var(--menu-item-hover-color);
  background-color: var(--color-white);
  margin-bottom: .5rem;
}

.member-queue-page__member-row {
  border-top: 1px solid var(--text-hover-color);
}

.member-queue-page__member-row--actions {
  padding-bottom: .5rem;
}

.member-queue-page__member-row--saving td {
  background-color: var(--menu-item-hover-color);
}

.schedule-meeting {
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.schedule-meeting__members {
  align-items: center;
  gap: 2rem;
  margin: 0 auto;
  display: flex;
}

.schedule-meeting__members-avatar {
  width: 4rem;
}

.schedule-meeting__lock-page {
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  display: flex;
}

.schedule-meeting__sad-cat {
  max-width: 20rem;
  margin: 2rem auto;
  display: block;
}

.schedule-meeting__submit-button {
  margin: 1rem auto 0;
  display: block;
}

.schedule-meeting__help-link {
  text-align: center;
  margin: 1rem auto 0;
  font-weight: 700;
  display: block;
}

.schedule-meeting__scheduled-date {
  justify-content: center;
  gap: 1.5rem;
  margin: 2.5rem auto;
  display: flex;
}

@media only screen and (max-width: 1080px) {
  .schedule-meeting {
    gap: 1rem;
  }
}

.info-message {
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

.info-message__message {
  border: 4px solid var(--color-voco-faded);
  background-color: var(--color-white);
  width: 32rem;
  padding: 1rem;
  position: relative;
}

.info-message__message-left {
  margin-right: 6rem;
}

.info-message__message-right {
  margin-left: 6rem;
}

.info-message__message-callout {
  width: 27px;
  height: 22px;
  position: absolute;
  top: 30px;
}

.info-message__message-callout-right {
  right: -27px;
}

.info-message__message-callout-left {
  left: -27px;
}

.info-message__avatar {
  width: 4rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 768px) {
  .info-message__message {
    width: auto;
    max-width: 32rem;
    margin: 0 1rem;
  }

  .info-message__avatar {
    display: none;
  }
}

.meeting-scheduler {
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  display: flex;
}

.meeting-scheduler__week-view {
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  display: flex;
}

.meeting-scheduler__week {
  opacity: 1;
  gap: 1rem;
  transition: opacity .4s;
  display: flex;
}

.meeting-scheduler__week-button {
  width: 46px;
  height: 101px;
  background-color: var(--color-voco-grey-teal);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: .75rem;
  margin-right: .75rem;
  display: flex;
}

.meeting-scheduler__week-button:disabled, .meeting-scheduler__week--fade {
  opacity: 0;
}

.meeting-scheduler__day, .meeting-scheduler__times {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.meeting-scheduler__date-header {
  margin-bottom: 1rem;
}

.meeting-scheduler__week-button-text {
  color: var(--color-voco-teal);
  font-weight: 700;
  display: none;
}

@media only screen and (max-width: 1080px) {
  .meeting-scheduler, .meeting-scheduler__week-view, .meeting-scheduler__week {
    flex-direction: column;
    gap: .5rem;
  }

  .meeting-scheduler__day, .meeting-scheduler__times {
    flex-direction: row;
    align-items: center;
  }

  .meeting-scheduler__times {
    width: 17rem;
    scroll-snap-type: x mandatory;
    touch-action: auto;
    padding: 1rem 0;
    overflow-x: scroll;
  }

  .meeting-scheduler__times::-webkit-scrollbar {
    display: none;
  }

  .meeting-scheduler__time {
    scroll-snap-align: start;
  }

  .meeting-scheduler__date-header {
    margin-bottom: 0;
  }

  .meeting-scheduler__week-button {
    height: 37px;
    width: 100%;
    margin: 0;
  }

  .meeting-scheduler__week-button-icon {
    display: none;
  }

  .meeting-scheduler__week-button-text {
    display: inline-block;
  }
}

.date-tile {
  width: 101px;
  height: 101px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.date-tile--dark-teal {
  background-color: var(--color-voco-teal);
  color: var(--color-white);
}

.date-tile--orange {
  color: var(--color-action-orange);
}

.date-tile--disabled {
  background-color: var(--text-hover-color);
}

.date-tile__day, .date-tile__month {
  font-size: .875rem;
  font-weight: 700;
}

.date-tile__date {
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 2.25rem;
}

@media only screen and (max-width: 1080px) {
  .date-tile {
    width: 70px;
    height: 70px;
  }

  .date-tile__date {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.25rem;
  }

  .date-tile__day, .date-tile__month {
    font-size: .625rem;
    font-weight: 700;
  }
}

.time-tile {
  border: 2px solid var(--color-voco-teal);
  width: 101px;
  height: 50px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.time-tile--large {
  width: 101px;
  height: 101px;
}

.time-tile--selected {
  border-color: var(--voco-button-blue);
  background-color: var(--voco-light-blue);
}

.time-tile--disabled {
  cursor: not-allowed;
  background-color: var(--text-hover-color);
  border: none;
}

.time-tile--disabled .time-tile__time, .time-tile--disabled .time-tile__ampm {
  color: var(--color-white);
}

.time-tile__time, .time-tile__ampm {
  color: var(--color-voco-teal);
  font-weight: 700;
}

.time-tile__time {
  margin-right: .25rem;
}

.time-tile__ampm {
  font-size: .75rem;
  line-height: .75rem;
}

.time-tile__emoji {
  font-size: 1.5rem;
  position: absolute;
  bottom: -.75rem;
  right: -.25rem;
}

@media only screen and (max-width: 1080px) {
  .time-tile {
    width: 50px;
  }

  .time-tile--large {
    width: 70px;
    height: 70px;
  }

  .time-tile__time {
    margin-right: .1rem;
  }

  .time-tile__emoji {
    font-size: 1.25rem;
    bottom: -.65rem;
    right: -.65rem;
  }
}

.new-subscription {
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 3fr 2fr;
  margin-top: 8.5rem;
  display: grid;
}

.new-subscription__info {
  place-self: center end;
}

.new-subscription__info-text {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.825rem;
}

.new-subscription__thanks {
  font-size: 2.5rem;
  line-height: 3rem;
}

.new-subscription__image {
  grid-area: 2 / 1;
  place-self: end center;
}

.new-subscription__pricing-table {
  grid-row: 1 / span 2;
  justify-self: start;
}

@media only screen and (max-width: 1025px) {
  .new-subscription__thanks {
    font-size: 2rem;
    line-height: 2.25rem;
  }

  .new-subscription {
    max-width: 100%;
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    margin-top: 2rem;
  }

  .new-subscription__info-text {
    grid-row: 1;
    place-self: start center;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  .new-subscription__image {
    grid-row: 3;
    align-self: end;
  }

  .new-subscription__pricing-table {
    grid-row: 2;
    justify-self: center;
  }
}

.subscription-confirmation {
  grid-template-rows: 5rem 1fr 1fr;
  grid-template-columns: 3fr 2fr;
  margin-top: 8.5rem;
  display: grid;
}

.subscription-confirmation__info {
  grid-row: 1 / span 2;
  place-self: start end;
  margin-left: 1rem;
}

.subscription-confirmation__image {
  grid-area: 2 / 2 / span 2;
  place-self: end start;
}

.subscription-confirmation__info-text {
  text-transform: none;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.825rem;
}

@media only screen and (max-width: 1025px) {
  .subscription-confirmation {
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    margin-top: 2rem;
  }

  .subscription-confirmation__info {
    grid-area: 1 / 1;
    justify-self: center;
  }

  .subscription-confirmation__image {
    grid-area: 2 / 1;
    justify-self: end;
  }

  .subscription-confirmation__info-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.subscription-banner {
  height: 3rem;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.link-button--primary.subscription-banner__link {
  color: var(--color-white);
}

.match-journey-section__guides {
  margin-bottom: 4rem;
}

.match-journey-section__empty-state {
  height: 100%;
  min-height: 2rem;
  align-items: center;
  display: flex;
}

.match-journey-section__empty-state-text {
  color: var(--color-action-orange);
}

.match-journey-section__intro {
  margin: 3rem auto;
}

@media only screen and (max-width: 1080px) {
  .match-journey-section__empty-state-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}

.journey-step {
  align-items: stretch;
  padding-bottom: 3rem;
  display: flex;
  position: relative;
}

.journey-step__no-date {
  width: 101px;
  height: 101px;
}

.journey-step__path {
  width: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.journey-step__path-bar {
  height: 100%;
  width: 6px;
  background-color: var(--color-voco-darker-teal);
  position: absolute;
  left: 148px;
}

.journey-step:first-child .journey-step__path-bar {
  height: calc(100%-25px);
  top: 25px;
}

.journey-step:last-child .journey-step__path-bar {
  height: calc(100% - 100px);
  bottom: 100px;
}

.journey-step--orange .journey-step__path-bar {
  background-color: var(--color-action-orange);
}

.journey-step__diamond {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--color-white);
  border: 8px solid var(--color-voco-darker-teal);
  transform: rotate(45deg);
}

.journey-step--orange .journey-step__diamond {
  border-color: var(--color-action-orange);
}

.journey-step--orange .journey-step__diamond.journey-step__diamond--filled {
  background-color: var(--color-voco-lighter-faded);
}

.journey-step--teal .journey-step__diamond.journey-step__diamond--filled {
  background-color: var(--color-voco-light-teal);
}

.journey-step__date-mobile {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .journey-step .date-tile {
    display: none;
  }

  .journey-step__path {
    width: 50px;
  }

  .journey-step__path-bar {
    width: 4px;
    left: 23px;
  }

  .journey-step__no-date {
    display: none;
  }

  .journey-step__diamond {
    width: 1.25rem;
    height: 1.25rem;
    border-width: 4px;
  }

  .journey-step:last-child .journey-step__path-bar {
    height: calc(100% - 75px);
    bottom: 75px;
  }

  .journey-step__date-mobile {
    font-size: .625rem;
    display: block;
    position: absolute;
    bottom: 1.5rem;
    left: 50px;
  }

  .journey-step--orange .journey-step__date-mobile {
    color: var(--color-action-orange);
  }
}

.step-card {
  max-width: calc(68rem - 241px);
  display: flex;
}

.step-card__title {
  flex-shrink: 1;
}

.step-card__links {
  flex-shrink: 0;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.step-card--teal .step-card__links {
  border: 6px solid var(--color-voco-light-teal);
}

.step-card--orange .step-card__links {
  border: 6px solid var(--color-voco-lighter-faded);
}

.step-card--dark-orange .step-card__links {
  border: 6px solid var(--color-action-orange);
}

.step-card__link {
  text-transform: uppercase;
  text-align: center;
  color: var(--color-voco-light-teal);
  margin: auto 2rem;
  font-family: league-gothic, sans-serif;
  font-size: 1.5rem;
}

@media only screen and (max-width: 1080px) {
  .step-card {
    max-width: calc(100vw - 82px);
  }

  .step-card__link {
    max-width: 30px;
    margin: 1rem;
    font-size: 1rem;
    line-height: 1rem;
  }
}

.theme-page {
  margin-top: 4.5rem;
}

.theme-page__guide-button {
  border-radius: 22px;
  font-weight: 700;
}

.theme-page__footer {
  display: flex;
}

.theme-page__footer .theme-page__guide-button {
  margin: 2rem auto;
}

@media only screen and (max-width: 1080px) {
  .theme-page {
    margin-top: 2rem;
  }
}

.guide-page {
  margin-top: 4.5rem;
}

.guide-page__banner {
  max-width: 100%;
  margin: 1.5rem auto 0;
  display: block;
}

.guide-page__agenda-item, .guide-page__body-item {
  margin-top: 1.25rem;
}

@media only screen and (max-width: 1080px) {
  .guide-page {
    margin-top: 2rem;
  }
}

/*# sourceMappingURL=index.9320c5da.css.map */
